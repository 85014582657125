export function fetchResources() {
    var places = fetchPlaces();
    //var routes = fetchRoutes();
    return Promise.all([
        places,
        //routes,
    ]).then(values => {
        return {
            places: values[0].places,
            //route: values[1].route,
        };
    });
}

function fetchPlaces() {
    return fetchResource(`${process.env.BACKEND_URL}/places`);
}

function fetchRoutes() {
    return fetchResource(`${process.env.BACKEND_URL}/routes`);
}

function fetchResource(url) {
    // callback helper is so I can retry all in this function
    function callbackHelper(callback) {
        return fetch(url)
                .then(res => callback(res.json()))
                .catch((error) => {
                    console.warn(`Error loading resource ${url} - trying again in 10 seconds`);
                    console.error(error);

                    setTimeout(() => {
                        callbackHelper(callback);
                    }, 10000);
                });
    }

    return new Promise((resolve, reject) => {
        callbackHelper(resolve);
    });
}