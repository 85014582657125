import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

import {getResource, PLACEHOLDER_PLACE_IMAGE} from '../Resources/static_files';
import './ImageCarousel.css';

export class ImageCarousel extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    renderImageCarousel() {
        var imgComponents;
        if (this.props.images) {
            imgComponents = this.props.images.map(image => {
                return <Carousel.Item key={image}>
                    <img className="previewImage" src={getResource(image)} />
                </Carousel.Item>;
            });
        } else {
            imgComponents =
                <Carousel.Item>
                    <img className="placeholderImage" />
                    <Carousel.Caption>
                        <h3 style={{color: 'black'}}>Image not found</h3>
                    </Carousel.Caption>
                </Carousel.Item>;
        }

        var settings = {
            className: "carouselContainer",

            controls: true,
            indicators: false,
            interval: 5000,
            keyboard: false,
            pause: "hover",
            slide: true,
            touch: true
        };

        return (
            <Carousel {...settings}>
                {imgComponents}
            </Carousel>
        );
    }

    render() {
        return this.renderImageCarousel();
    }
}