// Import all static files for bundler to include
import files from '../../public/static_content/**/*.*';

/**
 * Resolve the static content. Paths must be relative to static content root.
 * 
 * @param {string} path The path of the resource. Of the form {dir1}/{dir2}/{resource name}.{extension}
 * @returns {string} Bundled static content location
 */
export function getResource(path) {
    var components = path.split('/');
    var resource = components.pop().split('.', 2);
    var resourceName = resource[0];
    var resourceExtension = resource[1];

    var resourceTable = files;
    for (const index in components) {
        resourceTable = resourceTable[components[index]];
    }

    return resourceTable[resourceName][resourceExtension];
}