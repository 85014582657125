import {getResource} from './../Resources/static_files'

import Icon from 'ol/style/Icon';
import {Stroke, Style} from 'ol/style';

export function UserLocationStyle() {
    return new Style({
        image: new Icon({
            opacity: 1,
            src: getResource('icons/persongeneric.svg'),
            scale: 0.125,
            anchor: [0.5, 0.5],
        })
    });
}

export function RouteStyle() {
    return new Style({
        stroke: new Stroke({
            color: [46, 254, 154, 0.70],
            width: 5,
            lineCap: "round",
        })
    });
}

/**
 * 
 * @param {string} colour Colour of marker
 */
const PlaceMarkerSVG = (colour, stroke_width) => {
    return `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="${colour}" stroke-width="${stroke_width}" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin">
        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
        <circle cx="12" cy="10" r="3"></circle>
    </svg>
    `;
};

/**
 * Style a place marker 
 * @param {boolean} selected Is the place marker currently selected by the user?
 * @returns {Style} Place marker style
 */
export function PlaceMarkerStyle(selected, noneSelected) {
    var colour = 'black';
    var stroke_width = 3;

    if (!selected && !noneSelected) {
        colour = 'slategrey';
        stroke_width = 2;
    } else if (noneSelected) {
        stroke_width = 2;
    }

    return new Style({
        image: new Icon({
            opacity: 1,
            src: 'data:image/svg+xml;utf8,' + PlaceMarkerSVG(colour, stroke_width),
            scale: 1,
            anchor: [0.5, 1],
        }),
    });
}