import React from 'react';

import './Map.css';
import {PlaceMarkerStyle, UserLocationStyle, RouteStyle} from './../Place/Style'

import 'ol/ol.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import TileImage from 'ol/source/TileImage';
import VectorLayer from 'ol/layer/Vector';
import Vector from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
import {fromLonLat, toLonLat} from 'ol/proj';
import Collection from 'ol/Collection';
import Feature from 'ol/Feature';

const LondonLonLat = [-0.118092, 51.509865]; // To center on London

export class MapComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPlace: null,
            placeMarkersFeatures: this.props.places?.map((p) => CreatePlaceMarker(p.latitude, p.longitude)),
            userLocationFeature: CreateUserLocationFeature(),
        }
    }

    componentDidMount() {
        // create map object with feature layer
        var map = new Map({
            target: this.refs.mapContainerRef,
            layers: [
                // Map layer
                GetMapLayer(),

                // Render place markers
                new VectorLayer({
                    source: new Vector({
                        features: this.state.placeMarkersFeatures,
                    })
                }),
                // Render user location
                new VectorLayer({
                    source: new Vector({
                        features: [this.state.userLocationFeature],
                    })
                }),
                // Render routes
                new VectorLayer({
                    source: new Vector({
                        features: CreateRoute(this.props.selectedRoute),
                    })
                }),
            ],
            view: new View({
                zoom: 13,
                center: fromLonLat(LondonLonLat),
                minZoom: 12,
                maxZoom: 18,
                enableRotation: false,
            }),
            controls: new Collection(), // To override default controls
        });

        map.on('click', this.handleMapClick.bind(this));

        this.setState({ 
            map: map,
        });

        this.withUserPosition(this.props.userPosition, map);
    }

    centerMap(lat, long)
    {
        this.state.map.getView().animate({
            center: fromLonLat([long, lat]),
        });
    }

    withUserPosition(position, map) {
        // Center map on location
        if (position) {
            var long = position.coords.longitude;
            var lat = position.coords.latitude;

            this.state.userLocationFeature.setGeometry(new Point(fromLonLat([long, lat])));
            //this.centerMap(lat, long, map);

            //this.highlightClosestPlaces(lat, long);
        }
    }

    handleMapClick(event) {
        var nearestPlace = this.locateNearestPlace(event.pixel);

        this.unselectMarker(this.placeToIndex(this.state.selectedPlace));

        if (nearestPlace) {
            this.centerMap(nearestPlace.latitude, nearestPlace.longitude);
            this.selectMarker(this.placeToIndex(nearestPlace));
            this.setState({selectedPlace: nearestPlace});
            this.props.showPlace(nearestPlace);
        } else {
            this.state.placeMarkersFeatures.map(p => {
                p.setStyle(PlaceMarkerStyle(null, true));
            });
            this.setState({selectedPlace: null});
            this.props.showNoPlace();
        }
    }

    locateNearestPlace(clickedPixel) {
        var distances = this.getRelativePlaceDistance(clickedPixel);

        distances.sort((a, b) => a['distance'] > b['distance']);

        var minDistancePlace = distances.find(() => true);
        if (minDistancePlace && minDistancePlace['distance'] < 20) {
            return this.props.places[minDistancePlace['index']];
        }

        return null;
    }

    getRelativePlaceDistance(coords) {
        var places = this.props.places;
        var distances = places.map((p, index) => {
            var pixel = this.state.map.getPixelFromCoordinate(fromLonLat([p.longitude, p.latitude]));
            return {
                'distance': Math.sqrt(Math.pow(Math.abs(pixel[0] - coords[0]), 2) + Math.pow(Math.abs(pixel[1] - coords[1]), 2)),
                'index': index
            };
        });

        return distances;
    }

    //highlightClosestPlaces(lat, long) {
    //    var distances = this.getRelativePlaceDistance(fromLonLat([long, lat]));

    //    distances.sort((a, b) => a['distance'] > b['distance']);
    //    console.log(distances)

    //    var closestPlacesIndex = distances.map(d => d['index']).slice(0, 5);
    //    closestPlacesIndex.map(index => {
    //        this.state.placeMarkersFeatures[index].setStyle(PlaceMarkerStyle(true, false));
    //    });
    //}

    placeToIndex(place) {
        if (place) {
            var index = this.props.places.indexOf(place);
            return index;
        }
    }

    selectMarker(index) {
        if (index) {
            this.state.placeMarkersFeatures.map(p => {
                p.setStyle(PlaceMarkerStyle(false, false));
            });

            var marker = this.state.placeMarkersFeatures[index];
            marker.setStyle(PlaceMarkerStyle(true, false));
        }
    }

    unselectMarker(index) {
        if (index) {
            var marker = this.state.placeMarkersFeatures[index];
            marker.setStyle(PlaceMarkerStyle(false));
        }
    }

    render() {
        return <div id="mapContainer" ref="mapContainerRef"></div>;
    }
}

function CreatePlaceMarker(lat, long) {
    var marker = new Feature({
        geometry: new Point(
            fromLonLat([long, lat])
        ),
    });

    marker.setStyle(PlaceMarkerStyle(true, true));

    return marker;
}

function CreateUserLocationFeature() {
    var marker = new Feature();

    marker.setStyle(UserLocationStyle());

    return marker;
}

function CreateRoute(route) {
    if (route) {
        var routeFeature = new Feature(new LineString(route.getPlacesCoordinates().map(cs => fromLonLat(cs))));
        routeFeature.setStyle(RouteStyle());
        return [routeFeature];
    }
}

function GetMapLayer() {
    // Default OSM layer
    //import OSM from 'ol/source/OSM';
    //return new TileLayer({
    //    preload: 20,
    //    source: new OSM()
    //});

    // HERE maps
    return new TileLayer({
        preload: 20,
        source: new TileImage({ url: 'https://{1-4}.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png?apiKey=mb9-_KhlNdVsnQBP3CLJPBOmdeZ9uZ475-eL1veSMuU' })
    });
}