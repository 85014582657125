import React from 'react';
import './Header.css';

import {Navbar, Nav, NavDropdown} from 'react-bootstrap';

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className="header">
            <Navbar bg="light" expand="lg">
                <Navbar.Brand href="#map">Local Gems</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <NavDropdown title="About" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#aboutus">About Us</NavDropdown.Item>
                            <NavDropdown.Item href="#contactus">Contact Us</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="#submitAPlace">Submit a place</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
  }
}

export default Header;