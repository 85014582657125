import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Octicon from 'react-octicon';

import {ImageCarousel} from './ImageCarousel';
import {getResource} from './../Resources/static_files'
import './PlaceInfo.css';

export class PlaceInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    renderGetMeThere() {
        var domain = "citymapper.com";
        var path = "directions";

        var parameters = {
            'endcoord': this.props.place.latitude + "," + this.props.place.longitude,
            'endname': this.props.place.name,
        };

        var userPosition = this.props.userPosition;
        if (userPosition) {
            parameters['startcoord'] = userPosition.coords.latitude + "," + this.props.userPosition.coords.longitude;
        }

        var encodedParameters = "";
        var parameterKeys = Object.keys(parameters);
        for (var i = 0; i < parameterKeys.length; i++) {
            var parameter = parameterKeys[i];
            encodedParameters += `${parameter}=${encodeURIComponent(parameters[parameter])}`;

            if (i != parameterKeys.length - 1) {
                encodedParameters += "&"
            }
        }
        var cityMapperNavigationUrl = `https://${domain}/${path}?${encodedParameters}`;

        return (
            <a href={cityMapperNavigationUrl} target="_blank" style={{verticalAlign: "middle"}}>
                <img src={getResource('icons/compass.svg')} title="Get directions with Citymapper" alt="Get directions with Citymapper" />
            </a>
            );
    }

    renderActionBar() {
        // share, gem, get me there
        var getMeThere = this.renderGetMeThere();

        return (
            <div className="actionBar">
                <div className="actionItem">
                    <button type="button" className="btn btn-default btn-sm">
                        <img src={getResource('icons/share-2.svg')} title="Share" alt="Share" />
                    </button>
                </div>
                <div className="section-divider-vertical-line"></div>
                <div className="actionItem">
                    {getMeThere}
                </div>
                <div className="section-divider-vertical-line"></div>
                <div className="actionItem">
                    <button type="button" className="btn btn-default btn-sm">
                        <img src={getResource('icons/star.svg')} title="Star" alt="Star" />
                    </button>
                </div>
            </div>
        );
    }

    renderLinks() {
        var place = this.props.place;
        if (place.links) {
            return [
            <div key="0" className="section-divider-bottom-line"></div>,
            <div key="1" className="links">
                <a href={place.links}>
                    {place.links}
                </a>
            </div>];
        }
    }

    render() {
        var place = this.props.place;

        // Title
        // Picture
        // Description
        // Comments / feedback / tags ?...
        return <div className="placeInfo">
                    <ImageCarousel images={place.getImages()} />
                    <p className="placeName">{place.name}</p>
                    <div className="section-divider-bottom-line"></div>
                    {this.renderActionBar()}
                    <div className="section-divider-bottom-line"></div>
                    <p className="description">
                        {place.description}
                    </p>
                    {this.renderLinks()}
                    <div className="section-divider-bottom-line"></div>
                    <div className="closeButton" onClick={() => this.props.closeActivePlace()}>
                        <a href="#">Close</a>
                    </div>
               </div>;
    }
}