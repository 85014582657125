export class Place {
    constructor(id, name, latitude, longitude, description, links, images) {
        this.id = id;
        this.name = name;
        this.latitude = latitude;
        this.longitude = longitude;
        this.description = description;
        this.links = links;
        this.images = images;
    }

    getLonLat() {
        return [this.longitude, this.latitude];
    }

    getImages() {
        if (this.images && this.images !== "") {
            return this.images?.split(',')
        }
    }
}

export function translateToPlace(json) {
    return new Place(json.id, json.name, json.latitude, json.longitude, json.description, json.links, json.images)
}
