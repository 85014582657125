import {translateToPlace} from '../Place/Place';

export class Route {
    constructor(id, title, places, description) {
        this.id = id;
        this.title = title;
        this.places = places;
        this.description = description;
    }

    getPlacesCoordinates() {
        return this.places.map((p) => p.getLonLat());
    }
}

export function translateToRoute(json) {
    var places = json.places.map(p => translateToPlace(p));
    return new Route(json.id, json.title, places, json.description);
}