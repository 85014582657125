import React from 'react';
import './Resources/static_files'
import './App.css';
import {MapComponent} from './Map/Map';
import {translateToPlace} from './Place/Place';
import {PlaceInfo} from './Place/PlaceInfo';
import {translateToRoute} from './Route/Route';
import {fetchResources} from './Resources/FetchResources';
import Header from './Header';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.showPlace = this.showPlace.bind(this);
    this.showNoPlace = this.showNoPlace.bind(this);
    this.processResources = this.processResources.bind(this);
    this.state = {
      places: [],
      activePlace: null,
      selectedRoute: null,
      userPosition: null,
    };
  }

  componentDidMount() {
    fetchResources().then(r => this.processResources(r));

    this.tryGetLocation()
  }

  tryGetLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
        // Center map on location
        if (position) {
          this.setState({
            userPosition: position,
          });
        }
    });
  }

  processResources(result) {
    this.setState({
      places: result.places?.map(p => translateToPlace(p)),
      selectedRoute: result.route != null ? translateToRoute(result.route) : null,
    });
  }

  showPlace(place) {
    this.setState({
      activePlace: place
    });
  }

  showNoPlace() {
    this.setState({
      activePlace: null
    });
  }

  renderActivePlace(place, userPosition) {
    return <PlaceInfo place={place} closeActivePlace={this.showNoPlace} userPosition={userPosition} />
  }

  render() {
    var places = this.state.places;
    var activePlace = this.state.activePlace;
    var selectedRoute = this.state.selectedRoute;
    var userPosition = this.state.userPosition;

    // Use of key is a hack, to force unmount and remount of MapComponent to re-render map with new places
    return (
      <div>
        <Header />
        <div className="App">
          <div className="Overlay">
            {activePlace != null ? this.renderActivePlace(activePlace, userPosition) : null}
          </div>
          <div className="Sidebar">
            <p> test </p>
          </div>
          <MapComponent places={places} showPlace={this.showPlace} showNoPlace={this.showNoPlace} selectedRoute={selectedRoute} userPosition={userPosition} key={[places, userPosition]} />
          <div style={{position: "absolute", zIndex: -1, textAlign: "center", width: "100%"}}>Loading map...</div>
        </div>
      </div>);
  }
}

export default App;